const orderTypeDict = {
  //采购入库
  buy: 1,
  //其他入库
  otherIn: 2,
  //销退入库
  sellBack: 3,
  //调拨入库
  allotIn: 4,
  //盘点入库
  inventoryIn: 5,
  //其他出库
  otherOut: 6,
  //销售出库
  sell: 7,
  //调拨出库
  allotOut: 8,
  //盘点出库
  inventoryOut: 9,
  //采退出库
  buyBack: 10,
  //报损出库
  damage: 11,
}

export const orderLabelStatusMap = {
    BIND: 1,
    DAMAGE: 2,
    SUCCESS: 3,
    UNSALABLE: 4,
    ADVENT: 5,
    EXPIRE: 6,
    OUT: 7
}

export const orderOperateType = {
    BUY: 1,
    OTHER_IN: 2,
    SELL_BACK: 3,
    TRANSFER_IN: 4,
    CHECK_IN: 5,
    OTHER_OUT: 6,
    SELL_OUT: 7,
    TRANSFER_OUT: 8,
    CHECK_OUT: 9,
    BUY_OUT: 10,
    LOSS_OUT: 11,
    BIND: 12,
    UNBIND: 13,
    CHANGE_OUT_TIME: 14,
    CHANGE_UNBIND: 15,
    CHANGE_BIND: 16,
    OPENING: 17,
    SCATTERED: 18,
    SCATTERED_OUT: 19,
    IN_BOX: 20,
    SCATTERED_IN: 21,
    SCATTERED_BOX: 22,
    SHELF: 23,
    UN_SHELF: 24,
}

// const needScattered = [orderTypeDict.sell, orderTypeDict.otherIn]
const needScattered = []
export const isScattered = (oderType)=>{
    return needScattered.includes(+oderType)
}

//列表根据modelId 和 scattered 去重
export const uniqueProductList = (list = [] ,list2 = [])=>{
  let newList1 = [...list]
  let newList2 = [...list2]
  while(newList2.length){
    let item = newList2.shift()
    let index = newList1.findIndex((v)=>v.modelId === item.modelId && v.scattered === item.scattered)
    if(index !== -1){
      newList1.splice(index,1)
    }
      let index2 = newList1.findIndex((v) => v.modelId === item.modelId)
      if (index2 !== -1) {
          newList1[index2].realLen = 1
      }
  }
  return newList1
}

export const getUnitName = (data) => {
    return data.scatteredName ? data.scattered ? setProductUnitName(true, data) : setProductUnitName(false, data) : data.unitName
}

export const getUnitNameLabel = (data) => {
    return (data.scatteredName && data.labelStatus !== orderLabelStatusMap.BIND && data.labelStatus !== orderLabelStatusMap.OUT) ? data.scattered && data.scatteredStock !== data.scatteredCount ? setProductUnitName(true, data) : setProductUnitName(false, data) : data.unitName
}

export const setProductUnitName = (isScattered, data) => {
    return isScattered ? `${data.scatteredName}<span class='scattered-scattered--text'>（零）</span>` : `${data.unitName}<span class='scattered-monolithic--text'>（整）</span>`
}

export const getStockCount = (data) => {
  return data.scattered ? data.scatteredStockCount : data.stockCount
}