import number from './inputNumber' // 引入指令
import num from './inputOnlyNumStr' // 引入指令
import input from './input' // 引入指令
import increase from "./increase" //引入指令
import scrollBottom from './scrollBottom'
import clickOutside from "@utils/directives/clickOutside";
import scaleClick from './scaleClick'
import elInput from "@utils/directives/elInput";

const directives = { // 指令对象
  number,
  num,
  input,
  increase,
  scrollBottom,
  clickOutside,
  scaleClick,
  elInput
}
export default {
  install(app) {
    Object.keys(directives).forEach((key) => {
      app.directive(key, directives[key])
    });
  }
}
