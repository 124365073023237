<template>
  <el-empty description="暂无数据"/>
</template>

<script setup>

</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "WyEmpty",
});
</script>

<style lang="scss" scoped>

</style>
