/*
 * @Author: wangxinlie 278019672@qq.com
 * @Date: 2023-07-23 19:02:13
 * @LastEditors: wangxinlie 278019672@qq.com
 * @LastEditTime: 2023-08-17 10:59:20
 * @FilePath: /aurora-carton-web/src/utils/http.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios'
import router from '../router'
import resetMessage from '@utils/tool/message';
import {managerUrl} from './api'
import {useLoading} from '@/utils/tool/loading.js'
import {getToken, removeToken} from '@/utils/tool/token.js'
import {signature} from '@utils/tool'

axios.defaults.baseURL = managerUrl
axios.defaults.timeout = 60000
//全局loading
const {setLoading} = useLoading();

// 开始请求设置，发起请求拦截
axios.interceptors.request.use(config => {
  const signatureObj = signature()
  const token = getToken()
  const publicParams = {
    app: 1,
    token: token,
    version: '1.0.0',
    appVersion: '20230307'
  }
  // if(config.method.toUpperCase() === 'POST') {
  //   config.headers['Content-Type'] = 'application/json;charset=utf-8'
  // }
  if(config.method.toUpperCase() === 'GET') {
    config.params = Object.assign(
      {},
      config.data,
      publicParams,
      signatureObj
    )
  }
  if(config.method.toUpperCase() === 'POST') {
    // if (Array.isArray(config.data)) {
    //   config.data = [...config.data]
    // } else {
    //   if(!config.data?.get) {
    //     config.data = Object.assign(
    //         {},
    //         config.data
    //     )
    //   }
    // }
    config.params = Object.assign(
      publicParams,
      signatureObj
    )
  }
  if (config?.showLoading !== false) {
    setLoading(true);
  }

  return config
}, error => {
  return Promise.reject(error)
})


// response 响应拦截器
axios.interceptors.response.use(
  response => {
    const res = response.data
    setLoading(false);
    if (res.code === 0) {
      return response.data
    } else if (res.code === 7000 || res.code === 6100) {
        resetMessage.error(res.msg)
      // 登录失效
      removeToken()
      //判断当前页面
      if(router.currentRoute?.value?.fullPath && router.currentRoute?.value?.fullPath.indexOf('shop') > -1) {
        router.replace({
          name: 'shopLogin'
        })
      }else{
        router.replace({
          name: 'Login'
        })
      }
      return response.data
    } else {
      return response.data
    }
  },
  error => {
    resetMessage.error('网络故障')
    setLoading(false);
    return Promise.reject(error)
  }
)

export default axios
