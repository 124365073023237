import request from '../../http'
import {managerUrl} from '../../api'
//产品基本信息

//上传文件
export function uploadFile(data) {
  return request({
    url: managerUrl + "/carton/product/info/upload/excel",
    method: 'Post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
  })
}

//产品详情
export function productInfo(data){
  return request({
    url:managerUrl + "/carton/product/info/detail",
    method:'post',
    data
  })
}

//产品详情查询箱单关系
export function productPacking(data){
  return request({
    url:managerUrl + "/carton/product/packing/product",
    method:'post',
    data
  })
}

//产品搜索
export function productSearch(data){
  return request({
    url:managerUrl + "/carton/product/info/search",
    method:'post',
    data
  })
}

//产品信息列表
export function productCensus(data){
  return request({
    url:managerUrl + "/carton/product/info/census",
    method:'post',
    data
  })
}
//产品信息列表
export function productList(data){
  return request({
    url:managerUrl + "/carton/product/info/list",
    method:'post',
    data
  })
}

//废弃列表
export function productDiscardList(data){
  return request({
    url:managerUrl + "/carton/product/info/list/discard",
    method:'post',
    data
  })
}

//废弃产品
export function productDiscard(data){
  return request({
    url:managerUrl + "/carton/product/info/discard",
    method:'post',
    data
  })
}
//恢复产品
export function productResume(data){
  return request({
    url:managerUrl + "/carton/product/info/resume",
    method:'post',
    data
  })
}
//新增产品
export function productAdd(data){
  return request({
    url:managerUrl + "/carton/product/info/add",
    method:'post',
    data
  })
}


//编辑产品
export function productEdit(data){
  return request({
    url:managerUrl + "/carton/product/info/edit",
    method:'post',
    data
  })
}


//自定义模板
export function customTemplate(data){
  return request({
    url:managerUrl + "/carton/product/info/custom/template",
    method:'post',
    data
  })
}


//自定义模板保存
export function customSave(data){
  return request({
    url:managerUrl + "/carton/product/info/custom/save",
    method:'post',
    data
  })
}


//库存列表
export function stockProductInfo(data){
  return request({
    url:managerUrl + "/carton/product/stock/product/info",
    method:'post',
    data
  })
}


//库存列表
export function stockProductIn(data){
  return request({
    url:managerUrl + "/carton/product/stock/product/stock/in",
    method:'post',
    data
  })
}

//库存列表
export function stockProductOut(data){
  return request({
    url:managerUrl + "/carton/product/stock/product/stock/out",
    method:'post',
    data
  })
}


//入库类型列表
export function stockTypeList(data) {
    return request({
        url: managerUrl + "/carton/product/stock/info/type",
        method: 'post',
        data
    })
}

//产品详情价格
export function productPrice(data) {
    return request({
        url: managerUrl + "/carton/product/info/detail/price",
        method: 'get',
        data
    })
}