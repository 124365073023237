import {createRouter, createWebHistory} from "vue-router";
import {getToken} from "@utils/tool/token";


const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("../views/shop/index/index.vue"),
      meta: {requiresAuth: false},
  },
    //商城layout
    {
      path: "/shop",
      component: () => import("../views/shop/layOut.vue"),
      children: [
        {
          //商城首页
          path: "/register",
          name: "register",
          component: () => import("../views/shop/register/index.vue"),
            meta: {requiresAuth: false}
        },

        {
          //商城订单页面 直接购买
          path: "/shop/order/buy/:orderType",
          name: "shopOrderBuy",
          component: () => import("../views/shop/order/index.vue"),
            meta: {requiresAuth: true},
        },
        {
          //商城订单页面 //续费
          path: "/shop/order/renewal/:orderType",
          name: "shopOrderRenewal",
          component: () => import("../views/shop/order/index.vue"),
            meta: {requiresAuth: true}
        },
        {
          //商城升级页面
          path: "/shop/order/upLevel",
          name: "shopUpLevel",
          component: () => import("../views/shop/upLevel/index.vue"),
            meta: {requiresAuth: true}
        },
        {
          //商城商品列表
          path: "/shop/goodsList",
          name: "shopGoodsList",
          component: () => import("../views/shop/goodsList/index.vue"),
            meta: {requiresAuth: true}
        },
        {
          //商城商品列表确认订单
          path: "/shop/order/goodsList",
          name: "shopGoodsListOrder",
            component: () => import("../views/shop/goodsList/components/confirmGoodsOrder/index.vue"),
            meta: {requiresAuth: true}
        },
        {
          //商城首页
            path: "/shop/index",
          name: "shopIndex",
          component: () => import("../views/shop/index/index.vue"),
            meta: {requiresAuth: false}
        },
        {
          //商城了解更多
          path: "/shop/knowMore",
          name: "shopKnowMore",
          component: () => import("../views/shop/knowMore/index.vue"),
            meta: {requiresAuth: false}
        },
        {
          //扫描订单
          path: "/scanOrder",
          name: "scanOrder",
          component: () => import("../views/shop/scanOrder/index.vue"),
            meta: {requiresAuth: false}
        },
        {
          //商城登录页面
          path: "/shop/login",
          name: "shopLogin",
          component: () => import("../views/shop/shopLogin/index.vue"),
            meta: {requiresAuth: false}
        },
        {
          //商城个人中心首页
          path: "/shop/person",
          name: "shopPerson",
          component: () => import("../views/shop/personal/personal/index.vue"),
          children: [
            {
              path: "/shop/person/index",
              component: () =>
                import("@views/shop/personal/personal/components/personInfo.vue"), //个人中心
                meta: {requiresAuth: true}
            },
            {
              path: "/shop/person/order",
              component: () =>
                import("@views/shop/personal/personal/components/order.vue"), //个人中心
                meta: {requiresAuth: true}
            },
            {
              path: "/shop/person/address",
              component: () =>
                import(
                  "@views/shop/personal/personal/components/addressManagement.vue"
                ), //个人中心
                meta: {requiresAuth: true}
            },
          ],
        },
      ]
  },
  {
    path: "/shop/graphicCourse",
    name: "shopGraphicCourse",
    component: () => import("../views/shop/graphicCourse/index.vue"),
    children: [
      {
        path: "/shop/graphicCourse/:id",
        name: "shopGraphicCourseDetail",
        component: () => import("../views/shop/graphicCourse/components/rightContent/index.vue"),
        meta: {requiresAuth: false}
      },
    ],
    meta: {requiresAuth: false}
  },
  {
    path: "/shop/graphicCourse/search",
    name: "shopGraphicCourseSearch",
    component: () => import("../views/shop/graphicCourse/components/search/index.vue"),
    meta: {requiresAuth: false}
  },
  {
    path: "/print",
    name: "print",
    component: () => import("../views/print/index.vue"),
      meta: {requiresAuth: true}
  },
  {
    path: "/printAllot",
    name: "printAllot",
    component: () => import("../views/print/printAllot.vue"),
      meta: {requiresAuth: true}
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/login/index.vue"),
      meta: {requiresAuth: false}
  },
  {
    path: "/loginBycode",
    name: "loginBycode",
    component: () => import("../views/LoginByCode/index.vue"),
      meta: {requiresAuth: false}
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/home/index.vue"),
      meta: {requiresAuth: true}
  },
  {
    path: "/layout",
    name: "layout",
    redirect: "/product/productInformation",
    component: () => import("../views/layout.vue"),
    children: [
      {
        path: "/welcome",
        component: () => import("../views/welcome.vue"), //作品首页
          meta: {requiresAuth: true}
      },
      {
        path: "/product/productInformation",
        component: () =>
          import("../views/product/productInformation/index.vue"), //产品信息
          meta: {requiresAuth: true}
      },

      {
        path: "/product/customerList",
        component: () => import("../views/product/customer/list/index.vue"), //客户信息列表
          meta: {requiresAuth: true}
      },
      {
        path: "/product/supplierList",
        component: () => import("../views/product/supplier/list/index.vue"), //供应商信息列表
          meta: {requiresAuth: true}
      },
      {
        path: "/product/packingList",
        component: () => import("../views/product/packing/list.vue"), //箱单列表
          meta: {requiresAuth: true}
      },
      {
        path: "/product/typeList",
        component: () => import("../views/product/type/list.vue"), //产品分类
          meta: {requiresAuth: true}
      },
      {
        path: "/product/labelManagement",
        component: () => import("../views/product/labelManagement/index.vue"), //标签管理
          meta: {requiresAuth: true}
      },
      {
        name: "ProductOrder",
        path: "/procurement/order/:orderType",
        component: () => import("../views/order/index.vue"), //采购订单
          meta: {requiresAuth: true}
      },
      {
        path: "/procurement/board",
        component: () => import("../views/procurement/board/index.vue"), //采购看板
          meta: {requiresAuth: true}
      },
      {
        path: "/inbound/board",
        component: () => import("../views/inbound/board/index.vue"), //入库看板
          meta: {requiresAuth: true}
      },
      {
        path: "/inbound/history",
        component: () => import("../views/inbound/history/index.vue"), //入库历史
          meta: {requiresAuth: true}
      },
      {
        path: "/inbound/salesRefund/:orderType",
        component: () => import("../views/order/index.vue"), //销退入库
          meta: {requiresAuth: true}
      },
      {
        path: "/personal/center",
        component: () => import("../views/personal/center/index.vue"), //个人中心
          meta: {requiresAuth: true}
      },
      {
        path: "/personal/stock",
        component: () => import("../views/personal/stock/index.vue"), //仓库管理
          meta: {requiresAuth: true}
      },
      {
        path: "/personal/role",
        component: () => import("../views/personal/role/index.vue"), //角色管理
          meta: {requiresAuth: true}
      },
      {
        path: "/personal/user",
        component: () => import("../views/personal/user/index.vue"), //用户管理
          meta: {requiresAuth: true}
      },
      {
        path: "/inStock/board",
        component: () => import("../views/inStock/board/index.vue"), //库存看板
          meta: {requiresAuth: true}
      },
      {
        path: "/inStock/inventoryCheck",
        component: () => import("../views/inStock/inventoryCheck/index.vue"), //库存查看
          meta: {requiresAuth: true}
      },
      {
        path: "/inStock/inventoryRecord",
        component: () => import("../views/inStock/inventoryRecord/index.vue"), //盘点管理
          meta: {requiresAuth: true}
      },
      {
        path: "/inStock/flowRecord",
        component: () => import("../views/inStock/flowRecord/index.vue"), //流水管理
          meta: {requiresAuth: true}
      },
      {
        path: "/outStock/sellOut/:orderType",
        component: () => import("../views/order/index.vue"), //销售出库
          meta: {requiresAuth: true}
      },
      {
        path: "/statisticalReport/sellProfit",
        component: () =>
          import("../views/statisticalReport/sellProfit/index.vue"), //统计报表-销售毛利
          meta: {requiresAuth: true}
      },
      // {
      //   path: "/inbound/allocate/:orderType",
      //   component: () => import("../views/inbound/allocate/index.vue"), //调拨入库
      //     meta: {requiresAuth: true}
      // },
      {
        path: "/inbound/allocateV2/:orderType",
        component: () => import("../views/inbound/allocateV2/index.vue"), //调拨入库V2
          meta: {requiresAuth: true}
      },
      // {
      //   path: "/outStock/allocate/:orderType",
      //   component: () => import("../views/inbound/allocate/index.vue"), //调拨出库
      //     meta: {requiresAuth: true}
      // },
      {
        path: "/outStock/allocateV2/:orderType",
        component: () => import("../views/inbound/allocateV2/index.vue"), //调拨出库V2
          meta: {requiresAuth: true}
      },
      {
        path: "/outStock/miningRetreatOutStock/:orderType",
        component: () => import("../views/order/index.vue"), //采退出库
          meta: {requiresAuth: true}
      },
      {
        path: "/outStock/history/:orderType",
        component: () => import("../views/outStock/history/index.vue"), //出库历史
          meta: {requiresAuth: true}
      },
      {
        path: "/outStock/board",
        component: () => import("../views/outStock/board/index.vue"), //出库看板
          meta: {requiresAuth: true}
      },
      {
        path: "/statisticalReport/downList",
        component: () =>
          import("../views/statisticalReport/downList/index.vue"), //统计报表-下载队列
          meta: {requiresAuth: true}
      },
      {
        path: "/statisticalReport/dataStatistics",
        component: () =>
          import("../views/statisticalReport/dataStatistics/index.vue"), //统计报表-数据统计
          meta: {requiresAuth: true}
      },
      {
        path: "/personal/shelfManagement",
        component: () =>
            import("../views/personal/shelfManagement"), //系统管理-货架管理
        meta: {requiresAuth: true}
      },
      {
        //货架管理-管理页面
        path: "/personal/shelfManagement/layout",
        name: "personalShelfManagement",
        component: () => import("@views/personal/shelfManagement/component/layout.vue"),
        redirect: "/personal/shelfManagement/add",
        children: [
          {
            path: "/personal/shelfManagement/add",
            component: () =>
                import("@views/personal/shelfManagement/component/add/index.vue"), //新增货架
            meta: {requiresAuth: true, routeName: "/personal/shelfManagement", text: '添加货架'}
          },
          {
            path: "/personal/shelfManagement/update",
            component: () =>
                import("@views/personal/shelfManagement/component/update/index.vue"), //编辑货架
            meta: {requiresAuth: true, routeName: "/personal/shelfManagement", text: '编辑货架'}
          },
          {
            path: "/personal/shelfManagement/detail",
            component: () =>
                import(
                    "@views/personal/shelfManagement/component/detail/index.vue" //货架详情
                    ),
            meta: {requiresAuth: true, routeName: "/personal/shelfManagement", text: '货架详情'}
          },
        ],
      },
    ],
  },
  {
      path: "/personal/shelfManagement/shelfChild/print",
      component: () => import("@views/personal/shelfManagement/component/shelfDetailModal/QRcodeModal/index.vue"),
      meta: {requiresAuth: true}
  },
    {
    path: "/:pathMatch(.*)",
    component: () => import("../views/404.vue"),
      meta: {requiresAuth: false}
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      } else if (to.hash) {
        return {el: to.hash}
      } else {
        return {left: 0, top: 0}
      }
    }
});

router.beforeEach((to, from, next) => {

    if (
        to.meta.requiresAuth === false
  ) {
    next();
    return;
  }
  if (!getToken()) {
    //判断用户没有登录直接跳转到登录页面 并且记录来时的页面
    if (to.path.indexOf("shop") !== -1) {
      sessionStorage.setItem("WYLG_preLoginRoute", to.fullPath);
      next({
        path: "/shop/login",
      });
      return;
    } else {
      next({
        path: "/login",
      });
      return;
    }
  }
  // if (!getToken()) {
  //   //判断用户没有登录直接跳转到登录页面
  //   if (to.fullPath !== '/login') {
  //     next({
  //       path: '/login',
  //     })
  //     return
  //   }
  //   if (to.matched.length === 0) {
  //     router.push(to.path)
  //   }
  // }
  next();
});

export default router;
