/*
 * @Author: wangxinlie 278019672@qq.com
 * @Date: 2023-07-23 19:02:13
 * @LastEditors: wangxinlie 278019672@qq.com
 * @LastEditTime: 2023-08-22 10:41:26
 * @FilePath: /aurora-carton-web/src/main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import 'default-passive-events'
import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueUeditorWrap from "vue-ueditor-wrap"
import ElementPlus from 'element-plus'
import './assets/css/reset.css'
import 'element-plus/dist/index.css'
//引入全局过滤器
import filter from './utils/filter'
import VueCookies from 'vue-cookies'
//引入动画样式
import 'animate.css';
//引入全局样式变量
import './app.scss'
//全局引入图片预览功能
import preview from 'vue3-image-preview';
//引入动态主题色
import './assets/css/theme.scss'
//引入vant 样式
import 'vant/lib/index.css';
//引入全局自定义指令
import directives from './utils/directives';
import resetMessage from './utils/tool/message';
import * as ElIconModules from '@element-plus/icons-vue'

//全局引入zh-table
import zhTable from '@components/element/table'

//全局引入zhPopSPan
import zhPopSpan from '@components/form/Input'

//全局引入input
import WyInput from '@components/form/Input/index.vue'


const app = createApp(App)
//注册全局过滤器
app.config.globalProperties.$filters = filter
//全局挂载cookies
app.config.globalProperties.$cookies = VueCookies
//全局引入mitt
//全局注册吐死提示
//使用UI框架
app.use(ElementPlus)
// 全局注册组件
app.config.globalProperties.$message = resetMessage

//全局注册组件
app.component('zhTable',zhTable)
app.component('zhPopSpan', zhPopSpan)
app.component('WyInput', WyInput)

//统一注册图标
for(let iconName in ElIconModules){
  app.component(iconName,ElIconModules[iconName])
}

app.use(store).use(router).use(VueUeditorWrap).use(preview).use(directives).mount('#app')

