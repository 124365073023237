import VueCookies from 'vue-cookies'

const TOKEN = 'wyLoginToken_WYLG'
const USER = 'WYLGUSER'
const SELECTKEY = 'cd_selectkey'//存入cookie标识

const typeConfig = {
    local: 1,
    session: 2
}


export function getToken() {
    return localStorage.getItem(TOKEN) || sessionStorage.getItem(TOKEN) || ''
}

export function removeToken() {
    localStorage.removeItem(TOKEN)
    sessionStorage.removeItem(TOKEN)
    return localStorage.removeItem(TOKEN) || sessionStorage.removeItem(TOKEN)
}


export function setToken(token, type) {
    if (type === typeConfig.local) {
        return localStorage.setItem(TOKEN, token)
    } else if (type === typeConfig.session) {
        return sessionStorage.setItem(TOKEN, token)
    }
    return false
}

export function setUserInfo(data) {
    return VueCookies.set(USER, data, {expires: 7})
}

/*这块主要进行一个操作
* 存储一个点击标识符
* 这样在内页就可以指定显示板块
* */
export function setSelectKey(selectkey, name = SELECTKEY) {
    VueCookies.set(name, selectkey, {expires: 7})
}

export function getSelectKey(name = SELECTKEY) {
    return VueCookies.get(name)
}

export function removeSelectKey(name = SELECTKEY) {
    return VueCookies.remove(name)
}

export function getUser() {
    return VueCookies.get(USER)
}
