/*
 * @Author: wangxinlie 278019672@qq.com
 * @Date: 2023-07-23 19:02:13
 * @LastEditors: wangxinlie 278019672@qq.com
 * @LastEditTime: 2023-08-17 10:54:45
 * @FilePath: /aurora-carton-web/src/utils/api.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

let request_url_dictionaries = {
  dev: 'http://devapi.musaemotion.com', // 开发库
  test: 'http://testapi.musaemotion.com', // 测试库
  line: 'https://api.musaemotion.com', // 正式环境
}

const managerUrl = request_url_dictionaries[process.env.NODE_ENV];
const ossUrl = "https://oss.musaemotion.com"
const ossImgUrl = "https://oss.musaemotion.com/WEB/assets/img"
export{
    managerUrl,
    ossUrl,
    ossImgUrl
}
