//设置颜色配置
import {reactive} from "vue";
import {resetObj} from "@utils/tool";

//分类级联选择器的配置文件
export const typeOptions = {
    expandTrigger: "hover",
    value: "classifyId",
    label: "classifyName",
    children: "childList",
    checkStrictly: false,
}

export function getGoodsConfig() {
    const goodsInfo = reactive({
        orderSn: '',
        productId: '',
        modelId: "",
        productName: "",
        encode: [],
        unitName: "",
        modelName: "",
        checkPrice: "",
        brandName: "",
        checkCount: "",
        subName: "",
        price: "",
        img: ""
    })

    function retGoodsInfo() {
        resetObj(goodsInfo)
    }

    return {
        goodsInfo,
        retGoodsInfo
    }
}

export function isInOrder(orderType) {
    return +orderType < 6 || +orderType === 17 || +orderType === 20 || +orderType === 16
}

//展示箱零转化
export function isChangeScattered(orderType) {
    return orderType === 18 || orderType === 22
}

//展示拆零入箱，拆零出翔
export function isScatteredOutPacking(orderType) {
    if (orderType !== 19 && orderType !== 21) {
        return false
    }
    return {
        text: `拆零${orderType === 19 ? '出' : '入'}箱`
    }
}


export function getChangeUnitName(data) {
    return {
        afterCountUnit: +data.orderType === 18 ? `<span class='scattered-scattered--text'>（零）</span>` : `<span class='scattered-monolithic--text'>（整）</span>`,
        beforeCountUnit: +data.orderType === 22 ? `<span class='scattered-scattered--text'>（零）</span>` : `<span class='scattered-monolithic--text'>（整）</span>`,
    }
}

export function isOrder(orderType) {
    return +orderType < 12 || +orderType === 17
}

export const labelStatus = {
    bind: 1,
    damage: 2,
    success: 3,
    unSalable: 4,
    advent: 5,
    expire: 6,
    out: 7,
}
export const agent = /Chrome\/([\d.]+)/.test(navigator.userAgent)
