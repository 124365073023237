import {createStore} from "vuex";
import shop from './shop'
import product from "@/store/product";

export default createStore({
  state: {
    isLogin: false, // 判断是否登录
    isLoading: false, // 判断是否正在加载
    userInfo: {
      name:''
    }, //用户信息
    menuList:[],//导航信息
    menuCurrent:0,//导航选中index
    goodsInfo:{},//商品信息模型
    purchaseOrderInfo:{
      orderSn:'',
      supplierName:"",
      productName:[],
      createTime:'',
      createUserName:"",
      remark:"",
    },//采购订单模型
    addPackingInfo: {
      productId: '',
      modelId: '',
    },
      shopMealList: [],
      baseic: {}
  },
  getters:{},
  mutations: {
      BASEIC: (state, data) => {
          state.baseic = data
      },
      //设置shopMealList
      SET_SHOP_MEAL_LIST: (state, data) => {
          state.shopMealList = data
      },
      //设置addPackingInfo
      SET_ADD_PACKING_INFO: (state, data) => {
          state.addPackingInfo = data
      },
      //清除addPackingInfo
      CLEAR_ADD_PACKING_INFO: (state) => {
          state.addPackingInfo = {
              productId: '',
              modelId: '',
          }
      },
    USER_INFO: (state, data) => {
      //设置用户信息
      state.userInfo = { ...data };
    },
    IS_LOGIN: (state, isLogin) => {
      state.isLogin = isLogin;
    },
    MENU_LIST: (state,data) =>{
      //设置菜单
      state.menuList = data
    },
    CURRENT_MENU_LIST: (state,data) =>{
      //设置菜单
      state.menuCurrent = data
    },
    GOODS_INFO: (state,data) =>{
      //设置商品信息
      state.goodsInfo = data
    },
    PURCHASE_ORDER_INFO:(state,data)=>{
      state.purchaseOrderInfo = data
    },
    //设置loading
    SET_LOADING: (state, data) => {
      state.isLoading = data;
    },
  },
  actions: {
      baseic({commit}, data) {
          //设置baseic
          return new Promise((resolve) => {
              commit("BASEIC", data);
              resolve();
          })
      },
      setShopMealList({commit}, data) {
          //设置shopMealList
          return new Promise((resolve) => {
              commit("SET_SHOP_MEAL_LIST", data);
              resolve();
          })
      },
    setGoodsInfo({ commit }, data) {
      //设置商品信息
      return new Promise((resolve) => {
        commit("GOODS_INFO", data);
        resolve();
      })
    },
    setPurchaseOrderInfo({ commit }, data) {
      //设置采购订单详情
      return new Promise((resolve) => {
        commit("PURCHASE_ORDER_INFO", data);
        resolve();
      })
    },
      setUserInfo({commit}, data) {
      return new Promise((resolve) => {
        commit("USER_INFO", data);
        resolve();
      });
    },
    set_menu_list({ commit }, data){
      return new Promise((resolve) => {
        commit("MENU_LIST", data);
        resolve();
      });
    },
    isLogin({ commit }, isLogin) {
      return new Promise((resolve) => {
        commit("IS_LOGIN", isLogin);
        resolve();
      });
    },
  },
  modules: {
    shop,
    product
  },
});
