<!--
 * @Author: wangxinlie 278019672@qq.com
 * @Date: 2023-08-21 10:12:31
 * @LastEditors: wangxinlie 278019672@qq.com
 * @LastEditTime: 2023-08-21 15:32:16
 * @FilePath: /aurora-carton-web/src/components/element/table.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-table
      v-bind="attrs"
      style="width: 100%"
      height="100%"
      class="zh-no-table-border"
      :row-class-name="tableRowClassName"
      :cell-style="{ border: 'none' }"
      :header-row-style="{ border: 'none' }"
    >
      <slot></slot>
    <template #empty>
      <WyEmpty/>
    </template>
    </el-table>
</template>

<script setup>
import {useAttrs} from 'vue';
import {tableRowClassName} from "@/utils/tool.js";
import WyEmpty from "@components/element/empty/index.vue";

const attrs = useAttrs();
</script>

<style lang="scss" scoped>
</style>