//全局过滤器
import moment from "moment";
import Big from 'big.js'


//判断一个 字符串是否存在于一个数组里面
function hasStr(str = "", list = []) {
  if (list.includes(str)) {
    return true;
  }
  return false;
}

function objHasStr(key, str = "", list = []) {
  for (let i = 0; i < list.length; i++) {
    if (list[i][key] === str) {
      return true;
    }
  }
  return false;
}

function numberFormatter(time) {
  return time * 1000;
}

//数字转中文
function convertToChinaNum(num) {
  var arr1 = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
  var arr2 = [
    "",
    "十",
    "百",
    "千",
    "万",
    "十",
    "百",
    "千",
    "亿",
    "十",
    "百",
    "千",
    "万",
    "十",
    "百",
    "千",
    "亿",
  ]; //可继续追加更高位转换值
  if (!num || isNaN(num)) {
    return "零";
  }
  var english = num.toString().split("");
  var result = "";
  for (var i = 0; i < english.length; i++) {
    var des_i = english.length - 1 - i; //倒序排列设值
    result = arr2[i] + result;
    var arr1_index = english[des_i];
    result = arr1[arr1_index] + result;
  }
  //将【零千、零百】换成【零】 【十零】换成【十】
  result = result.replace(/零(千|百|十)/g, "零").replace(/十零/g, "十");
  //合并中间多个零为一个零
  result = result.replace(/零+/g, "零");
  //将【零亿】换成【亿】【零万】换成【万】
  result = result.replace(/零亿/g, "亿").replace(/零万/g, "万");
  //将【亿万】换成【亿】
  result = result.replace(/亿万/g, "亿");
  //移除末尾的零
  result = result.replace(/零+$/, "");
  //将【零一十】换成【零十】
  //result = result.replace(/零一十/g, '零十');//貌似正规读法是零一十
  //将【一十】换成【十】
  result = result.replace(/^一十/g, "十");
  return result;
}

//时间转换
/*
 * 金时间转换
 * @param {Number} time 时间戳
 * @param {String} format 类型
 */
function timeFormat(time, format = 1) {
	let str = format == 1 ? 'YYYY-MM-DD HH:mm' : "YYYY-MM-DD";
	if(!time) return '--';
  return moment(time).format(str);
}

//时间增加
function timeSwitch(time, num = 1, type = "days") {
  return moment(time).add(num, type).format("YYYY-MM-DD");
}

/*
 * 金额转换，厘转换为元/万元/亿，默认不用传类型，当传入类型时，返回指定类型值，不传时，自动判断返回“元”或“万元”
 * @param {Number} num 金额
 * @param {String} type 类型
 */
function toYuan(num, type = '', fixed = false ) {
  if (num === null || num === undefined || Number(num) == 'NaN') {
    return 0;
  }
  //num 转数字
  num = Number(num);
  //取出num里面的负号
  let minus = "";
  if (num < 0) {
    minus = "-";
    num = Math.abs(num);
  }
    // if(num === 0){
    //   return '--';
    // }
  const newNum = new Big(num);
  switch (type) {
    case "元":
      if (fixed) {
        return minus + newNum.div(1)
      } else {
        return minus + newNum.div(1).toFixed(2)
      }
    case "商城":
      if (fixed) {
        return minus + newNum.div(1000)
      } else {
        return minus + newNum.div(1000).toFixed(2)
      }
    case "万元":
      return minus + newNum.div(10000).toFixed(2)
    case "亿元":
      return minus + newNum.div(100000000).toFixed(2)
    default:
      if (num < 100000000) {
        if (num < 10000) {
          // return (num / 1000).toFixed(2) + "元";
          return minus + newNum.div( 1).toFixed(2) + "元";
        } else {
          // return (num / 10000000).toFixed(2) + "万元";
          return minus + newNum.div(10000).toFixed(2) + "万元";
        }
      } else {
        // return (num / 100000000000).toFixed(2) + "万元";
        return minus + newNum.div(100000000).toFixed(2) + "亿元";
      }
  }
}

export default {
  toYuan,
  timeSwitch,
  timeFormat,
  numberFormatter,
  hasStr,
  convertToChinaNum,
  objHasStr,
};
