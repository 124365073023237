// 引入aes
import CryptoJS from "crypto-js";
import {ref} from "vue";
import {useRoute} from "vue-router";
import hexMD5 from "md5";
import resetMessage from "./tool/message.js";
import _ from "lodash";
import {isScattered} from '@/utils/tool/orderTools'
import filter from "@/utils/filter";

//判断是否是对象
function isObject(obj){
   return obj !== null && typeof obj === 'object'
}


function isArray(arr){
  return Array.isArray(arr)
}

// 加密
function encrypt(password) {
  let key = CryptoJS.enc.Utf8.parse("FeTAeviBNANEz6bG");
  let srcs = CryptoJS.enc.Utf8.parse(password);
  let encrypted = CryptoJS.AES.encrypt(srcs, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
}

function tableRowClassName({ row, rowIndex }) {
    if (row.activeHigh === true) {
        return 'high-row radix-row imgList'
    }
  if (rowIndex % 2 === 0) {
    return "radix-row imgList";
  }
  return "even-row imgList";
}
function tableRowClassNameRelieve({ row, rowIndex }) {

if (rowIndex % 2 === 0) {
  return row.equalGoods ? 'equalGoods radix-row imgList' : "radix-row imgList";
}
 return row.equalGoods ? 'equalGoods even-row imgList' : "even-row imgList";
}

function tableRowClassNameNew({row}) {
  if (row.realIndex % 2 === 0) {
    return `radix-row imgList ${row.className || ''}`;
  }
  return `even-row imgList ${row.className || ''}`;
}

function guid() {
  let str = new Date().getTime().toString();
  let arr = str.split("");
  //获取三个10以内的随机数
  for (let i = 0; i < 3; i++) {
    arr.splice(
      Math.floor(Math.random() * arr.length),
      0,
      Math.floor(Math.random() * 10).toString()
    );
  }
  return arr.join("");
}

//扁平化数组
function flatten(arr) {
  return arr.reduce((pre, cur) => {
    return pre.concat(Array.isArray(cur) ? flatten(cur) : cur);
  }, []);
}

//reactive对象赋值
function reactiveAssign(target, source) {
  if (!target || !source) return;
  for (const key in source) {
    if (Object.hasOwnProperty.call(source, key)) {
      const element = source[key];
      if (key === "followTime") {
        target[key] = element;
      } else if (key === "hasEncoded") {
        target[key] = element;
      } else {
        target[key] = element;
      }
    }
  }
}

//根据逗号，分好，查分字符串
function splitList(arr = "") {
  let list = [];
  if (typeof arr === "string") {
    if (arr.indexOf("/") !== -1) {
      list = arr.split("/");
    } else if (arr.indexOf(",") !== -1) {
      list = arr.split(",");
    } else {
      list = [arr];
    }
  }
  return list;
}

/*
 * 去处list1中包含list2的数据，返回去除后的list1
 * @param {Array} list1
 * @param {Array} list2
 * @param {String} key
 */
function mergeList(list1 = [], list2 = [], key) {
  let list = [];
  if (list1.length > 0) {
    list = list1;
    list2.forEach((item) => {
        let index = list.findIndex((i) => i[key] === item[key]);
        if (index !== -1) {
          list.splice(index, 1);
        }
    });
  }
  return list;
}

//递归对象的children转换为数组
function treeToArray(tree, key = "child") {
  let list = [];
  if (tree && tree.length > 0) {
    tree.forEach((item) => {
      item = changeList(item, key).reverse();
      list.push(item);
    });
  }
  return list;
}

//重组数组
const changeList = (obj, key = "child") => {
  let result = [];
  if (obj[key]) {
    result = result.concat(changeList(obj[key]));
  }
  result.push(obj);
  return result;
};

// 压缩图片
function compressImg(img) {
  let canvas = document.createElement("canvas");
  let ctx = canvas.getContext("2d");
  //瓦片canvas
  let tCanvas = document.createElement("canvas");
  let tctx = tCanvas.getContext("2d");
  // let initSize = img.src.length;
  let width = img.width;
  let height = img.height;
  //如果图片大于四百万像素，计算压缩比并将大小压至400万以下
  let ratio;
  if ((ratio = (width * height) / 4000000) > 1) {
    ratio = Math.sqrt(ratio);
    width /= ratio;
    height /= ratio;
  } else {
    ratio = 1;
  }
  canvas.width = width;
  canvas.height = height;
  //    铺底色
  ctx.fillStyle = "#fff";
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  //如果图片像素大于100万则使用瓦片绘制
  let count;
  if ((count = (width * height) / 1000000) > 1) {
    // console.log("超过100W像素");
    count = ~~(Math.sqrt(count) + 1); //计算要分成多少块瓦片
    //      计算每块瓦片的宽和高
    let nw = ~~(width / count);
    let nh = ~~(height / count);
    tCanvas.width = nw;
    tCanvas.height = nh;
    for (let i = 0; i < count; i++) {
      for (let j = 0; j < count; j++) {
        tctx.drawImage(
          img,
          i * nw * ratio,
          j * nh * ratio,
          nw * ratio,
          nh * ratio,
          0,
          0,
          nw,
          nh
        );
        ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh);
      }
    }
  } else {
    ctx.drawImage(img, 0, 0, width, height);
  }

  //进行最小压缩
  let ndata = canvas.toDataURL("image/jpeg", 0.2);
  tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0;
  // console.log(ndata);
  return ndata;
}

function getOrderType() {
  const route = useRoute();
  const orderType = ref("");
  orderType.value = route.params.orderType || 0;
  return orderType;
}

//清空对象
function resetObj(target = {}) {
  for (const key in target) {
    if (Object.hasOwnProperty.call(target, key)) {
      if (Object.prototype.toString.call(target[key]) === "[object Object]") {
        target[key] = {};
      } else if (typeof target[key] == "number") {
        target[key] = "";
      } else if (typeof target[key] == "boolean") {
        target[key] = false;
      } else if (typeof target[key] == "string") {
        target[key] = "";
      } else if (Array.isArray(target[key])) {
        target[key] = [];
      } else {
        target[key] = "";
      }
    }
  }
}

//移除没有childList的数据
function removeNoChild(list = [], key = "childList") {
  return list.filter((item) => item[key] && item[key].length > 0);
}

//判断时间是否超过今天
function isOverToday(time) {
  let now = new Date().getTime();
  let date = new Date(time).getTime();
  return date > now;
}

function signature() {
  const apiPrivKey = "3!T7fdEffRl#$x9nJ0gre8hvz";
  const num = new Date().getTime();
  const str = String(num - 1539328140705);
  const mp = "aRHAbMQYiO";
  let ranNum = "";
  for (let i = 0; i < str.length; i++) {
    const n = str.charAt(i);
    ranNum += mp.charAt(n);
  }
  const sign = hexMD5(apiPrivKey + num);
  return {
    ranNum: ranNum,
    sign: sign,
  };
}

function formatFileSize(sizeInBytes) {
  const sizeInKB = sizeInBytes / 1024;
  const sizeInMB = sizeInKB / 1024;

  if (sizeInMB >= 1) {
    return `${sizeInMB.toFixed(2)} MB`;
  } else {
    return `${sizeInKB.toFixed(2)} KB`;
  }
}

// 写一个 金额转大小写的方法
function convertCurrency(money) {
  //汉字的数字
  let cnNums = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
  //基本单位
  let cnIntRadice = ["", "拾", "佰", "仟"];
  //对应整数部分扩展单位
  let cnIntUnits = ["", "万", "亿", "兆"];
  //对应小数部分单位
  let cnDecUnits = ["角", "分", "毫", "厘"];
  //整数金额时后面跟的字符
  let cnInteger = "整";
  //整型完以后的单位
  let cnIntLast = "元";
  //最大处理的数字
  let maxNum = 999999999999999.9999;
  //金额整数部分
  let integerNum;
  //金额小数部分
  let decimalNum;
  //输出的中文金额字符串
  let chineseStr = "";
  //分离金额后用的数组，预定义
  let parts;
  // 传入的参数为空情况
  if (money === "") {
    return "";
  }
  money = parseFloat(money);
  if (money >= maxNum) {
    return "";
  }
  // 传入的参数为0 或者 0.00 情况
  if (money === 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger;
    return chineseStr;
  }
  // 转为字符串
  money = money.toString();
  // indexOf 检测某字符在字符串中首次出现的位置 返回索引值（从0 开始） -1 代表无
  if (money.indexOf(".") === -1) {
    integerNum = money;
    decimalNum = "";
  } else {
    parts = money.split(".");
    integerNum = parts[0];
    decimalNum = parts[1].substr(0, 4);
  }
  //转换整数部分
  if (parseInt(integerNum, 10) > 0) {
    let zeroCount = 0;
    let IntLen = integerNum.length;
    for (let i = 0; i < IntLen; i++) {
      let n = integerNum.substr(i, 1);
      let p = IntLen - i - 1;
      let q = p / 4;
      let m = p % 4;
      if (n === "0") {
        zeroCount++;
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0];
        }
        zeroCount = 0;
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
      }
      if (m === 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q];
      }
    }
    // 最后+ 元
    chineseStr += cnIntLast;
  }
  // 转换小数部分
  if (decimalNum !== "") {
    let decLen = decimalNum.length;
    for (let i = 0; i < decLen; i++) {
      let n = decimalNum.substr(i, 1);
      if (n !== "0") {
        chineseStr += cnNums[Number(n)] + cnDecUnits[i];
      }
    }
  }
  if (chineseStr === "") {
    chineseStr += cnNums[0] + cnIntLast + cnInteger;
  } else if (decimalNum === "") {
    chineseStr += cnInteger;
  }

  return chineseStr;
}

//复制
function copyText(text) {
  let input = document.createElement("input");
  input.setAttribute("readonly", "readonly");
  input.setAttribute("value", text);
  document.body.appendChild(input);
  input.select();
  input.setSelectionRange(0, 9999);
  if (document.execCommand("copy")) {
    document.execCommand("copy");
  }
  document.body.removeChild(input);
  resetMessage.success("复制成功");
}

function setRem() {
  let deviceWidth = document.documentElement.clientWidth;
  if (deviceWidth > 750) {
    deviceWidth = 750;
  }
  document.documentElement.style.fontSize = deviceWidth / 7.5 + "px";
}

function addMeta(name, content, id) {
  //手动添加mate标签
  let meta = document.createElement("meta");
  meta.content = content;
  meta.name = name;
  meta.id = id;
  document.getElementsByTagName("head")[0].appendChild(meta);
}

function removeMeta(id) {
  let meta = document.getElementById(id);
  return meta.parentNode.removeChild(meta);
}
//根据Host 获取备案号
function getRecordNumber() {
  let recordNumber;
  let host = window.location.host;
  if (host.indexOf("fund") !== -1) {
    recordNumber = "渝ICP备2023001167号-2";
  } else if (host.indexOf("cool") !== -1) {
    recordNumber = "渝ICP备2023001167号-3";
  } else {
    recordNumber = "渝ICP备2023001167号-2";
  }
  return recordNumber;
}

function convertTime(seconds) {
  seconds = +seconds; //转数字
  let minutes = Math.floor(seconds / 60);
  let remainingSeconds = seconds % 60;
  return minutes + "分" + remainingSeconds + "秒";
}

const objectSpanMethod = (needColumList) => {
  return function ({ row, column, rowIndex, columnIndex }){
    if (needColumList.indexOf(columnIndex) !== -1) {
      if (row.realLen) {
        return {
          rowspan: row.realLen,
          colspan: 1,
        };
      } else {
        return {
          rowspan: 0,
          colspan: 0,
        };
      }
    }
  };
};

//重置产品列表
const restProductList = (list = [],callBack)=>{
  const newList = _.cloneDeep(list)
  return newList.map((item,index)=>{
      if(typeof callBack === 'function') {
          callBack(item)
      }
      if(item.scattered){
          return [
              {...item,
                  scatteredName:item.unitName,
                  scattered:false,
                  realLen:2,
                  realIndex:index,
                className: 'top-row'
              },
              {
                  ...item,
                  realLen:0,
                  realIndex:index,
                className: 'bottom-row'
              }
          ]
      }else{
          return {
              ...item,
              realLen:1,
              realIndex:index,
          }
      }
  }).flat()
}

const resetStockGoodsList = (list = [], callBack, type = 1) => {
    const newList = _.cloneDeep(list)
    function judge(item) {
        //type 1 ,库存列表
        // if (type === 1) {
        //     return item.scatteredAbnormalStock !== 0 || item.scatteredNormalStock !== 0
        // }
        return item.scattered
    }

    return newList.map((item, index) => {
        if (typeof callBack === 'function') {
          callBack(item, index)
        }
        if (judge(item)) {
            return [
                {
                    ...item,
                    scattered: false,
                    realLen: 2,
                    realIndex: index,
                    scatteredName: item.unitName,
                  className: 'top-row'
                },
                {
                    ...item,
                    scattered: true,
                    scatteredCount: item.scatteredCount,
                  scatteredName: item.scatteredName,
                    realLen: 0,
                    realIndex: index,
                  abnormalStock: item.scatteredAbnormalStock,
                  normalStock: item.scatteredNormalStock,
                  totalPrice: item.scatteredTotalPrice,
                  abnormalPrice: item.scatteredAbnormalPrice,
                  className: 'bottom-row'
                }
            ]
        } else {
            return {
                ...item,
                scattered: false,
                realLen: 1,
                realIndex: index,
            }
        }
    }).flat()
}

const resetGoodsList = (list, callBack) => {
  const newList = _.cloneDeep(list).sort((a, b) => b.scattered - a.scattered)
  for (let i = 0; i < newList.length; i++) {
    let item = newList[i]
    if (typeof callBack === 'function') {
      callBack(item)
    }
      let index2 = newList.findIndex((j) => !Object.is(item, j) && (j.modelId === item.modelId && j.modelId) && (!j.productId || j.productId === item.productId) && (item.scattered !== j.scattered ))
      if (index2 > -1) {
          newList[index2].scatterInfo = item
          newList[index2].scattered = true
          newList.splice(i, 1)
          i--
    }
  }
  return newList.map((item, index) => {
    if (item.scattered && item.scatterInfo) {
      return [
        {
          ...item,
          scattered: false,
          realLen: 2,
          realIndex: index,
          scatteredName: item.unitName,
          className: 'top-row'
        },
        {
          ...item.scatterInfo,
          scattered: true,
          realLen: 0,
          realIndex: index,
          className: 'bottom-row',
        }
      ]
    } else {
      return {
        ...item,
        realLen: 1,
        realIndex: index,
      }
    }
  }).flat()
}

function setScatteredName(item) {
    return item.scattered && isScattered(+item.orderType) ? item.scatteredName  : item.unitName
}

function filterPrice(obj) {
  //盘点传出的obj对象里面的key 包含price的，需要转换成元
  Object.keys(obj).forEach(key => {
    if (key.toUpperCase().includes('PRICE')) {
      obj[key] = filter.toYuan(obj[key], '元')
    }
  })
}

export {
  resetGoodsList,
  resetStockGoodsList,
  restProductList,
  convertTime,
  compressImg,
  treeToArray,
  mergeList,
  encrypt,
  tableRowClassName,
  guid,
  reactiveAssign,
  splitList,
  getOrderType,
  resetObj,
  flatten,
  removeNoChild,
  isOverToday,
  signature,
  formatFileSize,
  convertCurrency,
  copyText,
  setRem,
  addMeta,
  removeMeta,
  getRecordNumber,
  tableRowClassNameNew,
  objectSpanMethod,
  filterPrice,
  tableRowClassNameRelieve
};
