import {productInfo as productBaseInfo} from "@/utils/api/product/product";
export default {
    namespaced: true,
    state: {
        productInfo:{

       },//产品详情
    },
    getters: {
        //获取商品列表
        getProductInfo: state => {
            return state.productInfo
        }
    },
    mutations: {
        //设置商品列表
        SET_PRODUCT_INFO: (state, data) => {
            state.productInfo = data
        }
    },
    actions: {
        //设置商品列表
        setProductInfo({commit}, data) {
            return async (productId)=>{
                try {
                    const { data , msg , code } = await productBaseInfo({productId})
                    if(code !== 0 ){
                        throw new Error(msg)
                    }
                    commit("SET_PRODUCT_INFO", data);
                }
                catch (e) {
                }
            }
        }
    }
}