/*
 * @Author: wangxinlie 278019672@qq.com
 * @Date: 2023-07-23 19:02:13
 * @LastEditors: wangxinlie 278019672@qq.com
 * @LastEditTime: 2023-08-22 10:46:14
 * @FilePath: /aurora-carton-web/src/utils/tool/message.js
 * @Description: 重写弹窗
 */
import {ElMessage} from "element-plus";

let lastExecutedTime = 0;

class resetMessage {
    constructor() {
    this.success = this.success.bind(this);
    this.error = this.error.bind(this);
    this.warning = this.warning.bind(this);
    this.info = this.info.bind(this);
    this.loading = this.loading.bind(this);
    this.close = this.close.bind(this);
  }

    success(msg, duration = 3) {
    const currentTime = Date.now();
    if (currentTime - lastExecutedTime >= duration * 1000) {
      lastExecutedTime = currentTime;
      ElMessage.success(msg);
    } else {
        //关闭弹窗 并且重新设置时间，并且弹窗
        ElMessage.closeAll();
        lastExecutedTime = currentTime;
        ElMessage.success(msg);
    }
  }

    error(msg, duration = 3) {
    const currentTime = Date.now();
    if (currentTime - lastExecutedTime >= duration * 1000) {
      lastExecutedTime = currentTime;
      ElMessage.error(msg);
    } else {
        //关闭弹窗 并且重新设置时间，并且弹窗
        ElMessage.closeAll();
        lastExecutedTime = currentTime;
        ElMessage.error(msg);
    }
  }

    warning(msg, duration = 3) {
    const currentTime = Date.now();
    if (currentTime - lastExecutedTime >= duration * 1000) {
      lastExecutedTime = currentTime;
      ElMessage.warning(msg);
    } else {
        //关闭弹窗 并且重新设置时间，并且弹窗
        ElMessage.closeAll();
        lastExecutedTime = currentTime;
        ElMessage.warning(msg);
    }
  }

    info(msg, duration = 3) {
    const currentTime = Date.now();
    if (currentTime - lastExecutedTime >= duration * 1000) {
      lastExecutedTime = currentTime;
      ElMessage.info(msg);
    } else {
        //关闭弹窗 并且重新设置时间，并且弹窗
        ElMessage.closeAll();
        lastExecutedTime = currentTime;
        ElMessage.info(msg);
    }
  }

    loading(msg, duration = 3) {
    const currentTime = Date.now();
    if (currentTime - lastExecutedTime >= duration * 1000) {
      lastExecutedTime = currentTime;
      ElMessage.loading(msg);
    } else {
        //关闭弹窗 并且重新设置时间，并且弹窗
        ElMessage.closeAll();
        lastExecutedTime = currentTime;
        ElMessage.loading(msg);
    }
  }

    close() {
        ElMessage.closeAll();
  }
}
const message = new resetMessage();

export default message ;
