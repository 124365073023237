function clickInput(e) {
    const {input, el} = this;
    if (e.target !== el && e.target !== input) return;
    if (!input) return;
    if (typeof input.focus === 'function' && input !== document.activeElement) {
        input.focus();
    }
}

export default {
    mounted(el) {
        // 找到el下面的input元素
        const input = el.querySelector('input');
        el.addEventListener('click', clickInput.bind({
            input, el
        }));

    },
    beforeUnmount(el) {
        el.removeEventListener('click', clickInput);
    },
}
