import {computed} from 'vue';
import store from "@/store";

export function useLoading() {
    const loading = computed(() => store.state.isLoading);
    const setLoading = (data) => {
        store.commit('SET_LOADING', data);
    }
    return {
        loading,
        setLoading
    }
}