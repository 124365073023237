<template>
 <div class="wy-form-input el-input" @mouseenter="mouseEnter" @mouseleave="mouseLeave" v-bind="attr" :class="[ isFocus && 'isFocus']">
   <input class="input-inner" :type="type" :placeholder="placeholder" :maxlength="maxlength"  :value="modelValue" @input="changeValue" @focus="getFocus" @blur="getBlur"/>
   <el-icon class="closeIcon cur_p" @click="clear" v-if="showClear && modelValue && clearable"><CircleClose /></el-icon>
 </div>
</template>

<script setup>
import { toRefs , useAttrs,ref,watch} from 'vue';
const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: ''
  },
  type: {
    type: String,
    default: 'text'
  },
  placeholder: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    default: false
  },
  maxlength: {
    type: Number,
    default: 140
  },
  clearable: {
    type: Boolean,
    default: false
  }
})
const { modelValue , type ,placeholder,disabled,readonly,maxlength,clearable} = toRefs(props)
const isFocus = ref(false)
const showClear = ref(false)
const attr = useAttrs()
const emit = defineEmits(['update:modelValue'])
const changeValue = (e)=>{
  emit('update:modelValue', e.target.value)
}
const getFocus = ()=>{
  isFocus.value = true
}
const getBlur = ()=>{
  isFocus.value = false
}
const mouseEnter = (e)=>{
  showClear.value = true
}
const mouseLeave = (e)=>{
  showClear.value = false
}
const clear = ()=>{
  emit('update:modelValue', '')
}

</script>
<script>
export default {
  inheritAttrs: false
}
</script>

<style lang="scss" scoped src="./index.scss">

</style>