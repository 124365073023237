<!--
 * @Author: wangxinlie 278019672@qq.com
 * @Date: 2023-07-23 19:02:13
 * @LastEditors: wangxinlie 278019672@qq.com
 * @LastEditTime: 2023-08-21 15:25:19
 * @FilePath: /aurora-carton-web/src/App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
  <div>
    <!--    <el-alert title="请使用Chrome浏览器，获取最佳体验" class="agent-tips" v-if="!agent" type="error"/>-->
    <el-config-provider :locale="locale">
      <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
    </el-config-provider>
  </div>
</template>

<script>
import {defineComponent, onMounted} from "vue";
import {ElConfigProvider} from "element-plus";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import {useRoute} from "vue-router";
import {agent} from '@/utils/config'

export default defineComponent({
  components: {
    [ElConfigProvider.name]: ElConfigProvider,
  },
  setup() {
    const route = useRoute();
    onMounted(() => {
      //重置主题色
      document.body.style.setProperty("--el-dialog-padding-primary", "6px");
      document.body.style.setProperty("--el-border-radius-base", "8px");

    });
    // useEventListener(window,'click',clickPoint)
    return {
      locale: zhCn,
      route,
      agent
    };
  },
});
</script>

<style lang="scss">
@import "./app.scss";
#app {
  min-height: 100vh;
  background-color: #fafafb;
  //overflow: hidden;
}

.agent-tips {
  position: relative;
  z-index: 1000000;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "PingFang SC", "Helvetica Neue", STHeiti, "Microsoft Yahei", Tahoma, Simsun, sans-serif;
}
@media print {
  #app {
    width: 210mm;
    margin: 0;
    padding: 0;
  }
}

html {
  width: 100%;
}

.el-message-box__headerbtn {
  top: 0 !important;
}
</style>
