export default {
    namespaced: true,
    state: {
        goodsList: [],//商品列表
    },
    getters: {
        //获取商品列表
        getGoodsList: state => {
            return state.goodsList
        }
    },
    mutations: {
        //设置商品列表
        SET_GOODS_LIST: (state, data) => {
            state.goodsList = data
        }
    },
    actions: {
        //设置商品列表
        setShopGoodsList({commit}, data) {
            return new Promise((resolve) => {
                commit("SET_GOODS_LIST", data);
                resolve();
            })
        }
    }
}