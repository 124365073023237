/*
 * @Author: wangxinlie 278019672@qq.com
 * @Date: 2023-07-23 19:02:13
 * @LastEditors: wangxinlie 278019672@qq.com
 * @LastEditTime: 2023-07-26 18:10:15
 * @FilePath: /aurora-carton-web/src/utils/directives/scrollBottom.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
 export default{
  updated(el) {
    // 这里的el即是绑定指令处的dom元素
     el.scrollTo({
       top: el.scrollHeight - el.clientHeight,
       behavior: "smooth"
     })
   }
 }