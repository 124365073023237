export default {
  // 在绑定元素的父组件
  // 及他自己的所有子节点都挂载完成后调用
  mounted(el, binding) {
    el.classList.remove('scaleClick');
    el.addEventListener('click', setAnimate)
    el.addEventListener('animationend', removeAnimate)
  },
  // 绑定元素的父组件卸载后调用
  unmounted(el) {
    el.removeEventListener('click', setAnimate)
    el.removeEventListener('animationend', removeAnimate)
  },
};


function setAnimate (){
  this.classList.add('scaleClick');
}
function removeAnimate (){
  this.classList.remove('scaleClick');
}