// 动画持续时间，ms
const DURATION = 1000;
/**
 * @description 基于Vue3自定义指令，实现数字递增动画效果
 *
 * @example     `<div v-increase="100"></div>`
 */
export default {
    // 在绑定元素的父组件
    // 及他自己的所有子节点都挂载完成后调用
    mounted(el, binding) {
        setDomNumber(el, binding);
    },
    // 绑定元素的父组件卸载后调用
    unmounted(el) {
        el.$animation.cancel();
    },
    //元素修改时
    updated(el, binding) {
        setDomNumber(el, binding);
    }
};


function setDomNumber(el, binding) {
    let {value: maxCount} = binding;
    let unit = ''
    //maxCount 是字符串，包含元，万元，亿元，将文字取出，剩下的转为数字包括负数
    if (typeof maxCount === "string") {
        //取出文字
        unit = maxCount.replace(/[\d\.\-]/g, '')
        //剩下的转为数字包括负数
        maxCount = Number(maxCount.replace(/[^\d\.\-]/g, '')) || 0
    }
    //maxCount 如果不是number类型，转化为number类型
    if (typeof maxCount !== "number") {
        maxCount = Number(maxCount) || 0
    }
    //如果maxCount 为小数
    if (maxCount % 1 !== 0) {
        el.$animation = animate((progress) => {
            el.innerText = (maxCount * progress).toFixed(2) + unit
        }, DURATION);
    } else {
        el.$animation = animate((progress) => {
            el.innerText = Math.floor(maxCount * progress) + unit
        }, DURATION);
    }
}

/**
 * @description             基于requestAnimationFrame，实现在持续时间内执行动画的方法
 *
 * @param fn                动画执行函数，参数progress表示完成的进度
 * @param duration          动画持续时间
 * @returns                  {cancel: cancel}
 */
export const animate = function (
    fn,
    duration
) {
    const animate = () => {
        animation = requestAnimationFrame(animate);
        const now = new Date().getTime();
        const progress = Math.floor(((now - START) / duration) * 100) / 100;
        fn(progress > 1 ? 1 : progress);
        // 到达持续时间，结束动画
        if (now - START > duration) {
            cancel();
        }
    };

    const cancel = () => {
        cancelAnimationFrame(animation);
    };

    const START = new Date().getTime();

    let animation = requestAnimationFrame(animate);
    return {
        cancel
    };
};
