export default {
    mounted(el, binding) {
        // 在指令绑定到元素时执行的代码
        const onClickOutside = (event) => {
            if (!el.contains(event.target) && el !== event.target) {
                binding.value()
            }
        };
        document.addEventListener('click', onClickOutside);
        el._clickoutside = onClickOutside;
    },
    beforeUnmount(el) {
        // 在指令从元素上解绑时执行的代码
        document.removeEventListener('click', el._clickoutside);
        delete el._clickoutside;
    },
}
